import React, { lazy, Suspense } from "react";
import LoadingPage from "./pages/loading";
import MainLayout from "./layouts/MainLayout";

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<LoadingPage />}>
    <Component {...props} />
  </Suspense>
);

const EventsPage = Loadable(lazy(() => import("./pages/events/index")));
const CompaniesPage = Loadable(lazy(() => import("./pages/companies/index")));
const ProjectsPage = Loadable(lazy(() => import("./pages/projects/index")));
const TriggersPage = Loadable(lazy(() => import("./pages/triggers/index")));
const TriggerLogsPage = Loadable(lazy(() => import("./pages/triggerLogs/index")));

const Router = () => {

  return [
    {
      path: "*",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <EventsPage />,
        },
        {
          path: "companies",
          element: <CompaniesPage />,
        },
        {
          path: "projects",
          element: <ProjectsPage />,
        },
        {
          path: "triggers",
          element: <TriggersPage />,
        },
        {
          path: "triggerlogs/:id",
          element: <TriggerLogsPage />,
        },
      ],
    },
  ];
};

export default Router;
